:root {
  --color-magenta: #e43366;
  --color-magenta-rgb: 228 51 102;
  --color-magenta-secondary: #bb294b;
  --color-magenta-secondary-rgb: 187 41 75;
  --color-white: #fff;
  --color-white-rgb: 255 255 255;
  --color-light: #ebebeb;
  --color-light-rgb: 235 235 235;
  --color-black: #000;
  --color-black-rgb: 0 0 0;
  --color-grey: #797979;
  --color-grey-rgb: 121 121 121;
  --color-violet: #210033;
  --color-line-rgb: 102 102 102;
}

@font-face {
  font-family: Overpass Regular;
  src: url("Overpass-Regular.236aedd2.woff2") format("woff2");
}

:root {
  letter-spacing: .1px;
  color: var(--color-white);
  font-family: Overpass Regular, Arial, sans-serif;
  font-size: 16px;
}

html, body {
  width: 100%;
  min-width: 360px;
  margin: 0;
  padding: 0;
}

body {
  scrollbar-width: thin;
  scrollbar-color: var(--color-magenta) rgb(var(--color-magenta-rgb) / 50%);
  background: #190027 url("background-dark.8f82bb5a.svg") center / cover no-repeat fixed;
  font-size: .875rem;
  line-height: 1.125rem;
}

.logo {
  margin: 3rem 1.5rem 2rem calc(100% - 207px - 4.5rem);
  display: inline-block;
}

.hexagon1 {
  --hexagon-image: url("phones.e846e35e.jpg");
  --hexagon-content-top: "Share";
  --hexagon-content-center: "your life,";
  --hexagon-content-bottom: "not your personal data.";
}

.hexagon2 {
  --hexagon-image: url("earth.ce200df4.jpg");
  --hexagon-content-top: "Decide";
  --hexagon-content-center: "who sees";
  --hexagon-content-bottom: "your private information.";
}

.hexagon3 {
  --hexagon-image: url("keyboard.ceae0e78.jpg");
  --hexagon-content-top: "Regain";
  --hexagon-content-center: "control";
  --hexagon-content-bottom: "over your digital identity.";
}

@media screen and (width >= 800px) {
  .logo {
    margin-left: calc(50% - 207px - 1.5rem);
  }
}

p {
  margin-block: 1.25rem;
}

@media screen and (width >= 800px) {
  .right, .left {
    width: 50%;
    max-width: 1000px;
  }
}

.left {
  box-sizing: border-box;
  margin-left: auto;
  padding-inline: 3rem;
}

@media screen and (width >= 800px) {
  .left {
    margin-right: 50%;
    padding-right: 0;
  }
}

.right {
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  top: 4rem;
  left: 50%;
}

@media screen and (width >= 800px) {
  .right {
    display: flex;
  }
}

.leftContainer {
  box-sizing: border-box;
  background: linear-gradient( 180deg, rgb(var(--color-white-rgb) / 10%) 0%, rgb(var(--color-black-rgb) / 12%) 100% ), #20013199;
  box-shadow: -2px -2px 9px 0 rgb(var(--color-white-rgb) / 20%), 2px 2px 9px 0 rgb(var(--color-black-rgb) / 20%);
  border-radius: .625rem;
  margin-bottom: 5rem;
  padding: 2rem;
}

@media screen and (width >= 800px) {
  .leftContainer {
    min-height: calc(100vh - 10rem);
    margin-bottom: auto;
  }
}

.rightContainer {
  filter: drop-shadow(-3px -3px 4px rgb(var(--color-white-rgb) / 30%) );
  width: 70%;
  aspect-ratio: 545 / 570;
  position: relative;
}

.rightContainer:before {
  content: "";
  background: var(--hexagon-image) no-repeat center / cover;
  clip-path: polygon(40.17% .37%, 89.98% 17.02%, 99.82% 66.58%, 59.83% 99.51%, 10.02% 82.86%, .18% 33.3%);
  width: 100%;
  height: 100%;
  padding-top: 3rem;
  display: block;
}

.rightHeadline {
  flex-wrap: wrap;
  align-items: baseline;
  margin-left: 10%;
  font-size: .5rem;
  font-weight: normal;
  line-height: 1.25;
  display: flex;
  position: absolute;
  top: 10%;
  left: 0;
}

.heading {
  margin: 0;
  font-size: 1.2rem;
  font-weight: normal;
  line-height: 1.25;
}

.buttonsLine {
  text-align: center;
}

.ctaButton {
  cursor: pointer;
  height: 2rem;
  text-transform: uppercase;
  color: var(--color-white);
  background-color: var(--color-magenta);
  box-shadow: 2px 2px 3px 1px rgb(var(--color-black-rgb) / 15%), -2px -2px 3px 1px rgb(var(--color-white-rgb) / 30%);
  border: none;
  border-radius: 1rem;
  justify-content: center;
  align-self: center;
  align-items: center;
  margin: .2rem auto;
  padding: 0 3rem;
  font-family: inherit;
  font-size: .875rem;
  text-decoration: none;
  display: inline-flex;
}

@media screen and (width >= 950px) {
  .rightHeadline {
    font-size: .6rem;
  }
}

@media screen and (width >= 1200px) {
  .rightHeadline {
    font-size: .7rem;
  }
}

@media screen and (width >= 1300px) {
  .rightHeadline {
    font-size: .8rem;
  }
}

@media screen and (width >= 1400px) {
  .rightHeadline {
    font-size: .9rem;
  }
}

@media screen and (width >= 1500px) {
  .rightHeadline {
    font-size: 1rem;
  }
}

.rightHeadlineTop {
  font-size: 4em;
  line-height: .8;
}

.rightHeadlineTop:after {
  content: var(--hexagon-content-top);
}

.rightHeadlineCenter {
  margin-left: .5em;
  margin-right: auto;
  font-size: 1.5em;
}

.rightHeadlineCenter:after {
  content: var(--hexagon-content-center);
}

.rightHeadlineBottom {
  margin-left: 5em;
  font-size: 1.5em;
}

.rightHeadlineBottom:after {
  content: var(--hexagon-content-bottom);
}

::-webkit-scrollbar {
  width: .625rem;
  display: block;
}

::-webkit-scrollbar-track {
  background: rgb(var(--color-magenta-rgb) / 50%);
}

::-webkit-scrollbar-thumb {
  background: var(--color-magenta);
  border-radius: .375rem;
}

@supports not (aspect-ratio: 545 / 570) {
  .rightContainer:before {
    padding-bottom: 100%;
  }
}

.footer {
  width: 100%;
  min-height: 2.125rem;
  background: var(--color-violet);
  border-top: 1px solid var(--color-magenta);
  place-content: center;
  display: flex;
  position: fixed;
  bottom: 0;
}

.content {
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 5rem;
  line-height: 2.25rem;
  display: flex;
}

@media screen and (width >= 700px) {
  .content {
    flex-direction: row;
  }
}

.navMenu {
  width: 100vw;
  border-bottom: 1px solid rgb(var(--color-line-rgb) / 75%);
  justify-content: center;
  gap: .625rem;
  font-size: .875rem;
  display: flex;
}

@media screen and (width >= 700px) {
  .navMenu {
    width: auto;
    border: 0;
  }
}

.navLink {
  color: var(--color-white);
  text-decoration: none;
}

.navLinkActive.navLink {
  opacity: .5;
}

.copyright {
  color: var(--color-white);
  background: url("built-on-KILT.7796ce9a.svg") right top .3125rem no-repeat;
  margin: 0;
  padding: 0 5.625rem 0 0;
  font-size: .625rem;
}

._2pkLjW_button {
  cursor: pointer;
  border: none;
  font-family: inherit;
}

._2pkLjW_buttonPrimary {
  height: 2rem;
  text-transform: uppercase;
  color: var(--color-white);
  background-color: var(--color-magenta);
  box-shadow: 2px 2px 3px 1px rgb(var(--color-black-rgb) / 15%), -2px -2px 3px 1px rgb(var(--color-white-rgb) / 30%);
  border-radius: 1rem;
  justify-content: center;
  align-self: center;
  align-items: center;
  padding: 0 3rem;
  font-size: .875rem;
  text-decoration: none;
  display: inline-flex;
}

._2pkLjW_buttonPrimary:active {
  transform: translateY(2px);
}

._2pkLjW_buttonPrimary:disabled {
  background-color: var(--color-grey);
  color: rgb(var(--color-white-rgb) / 50%);
}

._8gV6bq_input {
  width: 85%;
  height: 2.125rem;
  border: 1px solid var(--color-light);
  color: var(--color-white);
  box-shadow: 2px 2px 4px 0 rgb(var(--color-black-rgb) / 30%), -2px -2px 4px 0 rgb(var(--color-white-rgb) / 30%);
  background: #c1547680;
  border-radius: .625rem;
  margin-top: .25rem;
  margin-bottom: 1.25rem;
  padding: 0 .5rem;
  font-size: .875rem;
  display: block;
}

._8gV6bq_input:invalid, ._8gV6bq_input[aria-invalid], ._8gV6bq_input[data-invalid] {
  background: #752143;
}

._8gV6bq_error:not([hidden]) {
  width: calc(85% - 4rem);
  color: var(--color-magenta);
  background: var(--color-white) url("exclamation.06b9f46e.svg") no-repeat left .375rem center / 1.25rem;
  border-bottom-left-radius: .5rem;
  border-bottom-right-radius: .5rem;
  margin-inline: 1.5rem;
  padding: .375rem .5rem .25rem 2rem;
  display: inline-block;
}

.ZLXUVW_heading {
  margin: 0;
  font-size: 1rem;
  font-weight: normal;
}

.ZLXUVW_credentials {
  margin-top: 1.375rem;
  padding: 0;
  list-style: none;
}

.ZLXUVW_credential {
  --credential-logo: "";
  height: 2.5rem;
  background: var(--credential-logo) no-repeat left top 40% / 2.375rem, url("chevron-right.b0152936.svg") no-repeat left 2.5rem top 45% / .875rem;
  color: inherit;
  margin-block: 1rem;
  padding-left: 3.25rem;
  font-size: 1rem;
  line-height: 2.5rem;
  text-decoration: none;
  display: block;
}

.ZLXUVW_twitter {
  --credential-logo: url("twitter.e1591fed.svg");
}

.ZLXUVW_email {
  --credential-logo: url("email.c4c8713b.svg");
}

.ZLXUVW_discord {
  --credential-logo: url("discord.417087ac.svg");
}

.ZLXUVW_github {
  --credential-logo: url("github.417c7a8a.svg");
}

.ZLXUVW_twitch {
  --credential-logo: url("twitch.7adc4409.svg");
}

.ZLXUVW_telegram {
  --credential-logo: url("telegram.49ae3112.svg");
}

.ZLXUVW_youtube {
  --credential-logo: url("youtube_beta.8e5f21a4.svg");
}

.ZLXUVW_buttonPrimary {
  margin: 2.5rem auto;
  display: block;
}

.ZLXUVW_buttonLink {
  margin: 2.5rem auto;
}

.ZLXUVW_install {
  text-align: center;
  background: url("exclamation.70ae85f0.svg") top no-repeat, url("sporran-logo.1a5b46a2.svg") bottom no-repeat;
  margin-top: 2rem;
  padding-top: 4rem;
  padding-bottom: 7rem;
}

.ZLXUVW_unsupported {
  background-size: auto, 0;
  padding-bottom: 0;
}

.ZLXUVW_textLink {
  color: var(--color-magenta);
  text-decoration: none;
}

.ZLXUVW_textLink:hover {
  text-decoration: underline;
}

.ZLXUVW_webstore {
  height: 3rem;
  margin-bottom: 1rem;
  display: block;
}

.ZLXUVW_chrome {
  background: url("chrome.d73fc712.svg") center no-repeat;
}

.ZLXUVW_firefox {
  background: url("firefox.642d5704.svg") center no-repeat;
}

.ZLXUVW_connectContainer {
  margin-top: 3rem;
  position: relative;
}

.ZLXUVW_connect {
  height: 100%;
}

.ZLXUVW_connect[aria-busy="true"] {
  filter: blur(5px);
  pointer-events: none;
}

.ZLXUVW_extension {
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.ZLXUVW_extensionInput {
  max-width: 18.75rem;
  box-shadow: none;
  border: .75px solid rgb(var(--color-white-rgb) / 50%);
  border-radius: .25rem;
  margin: 0;
}

.ZLXUVW_authorize {
  text-align: center;
  margin: 3rem 0 1.75rem;
}

@keyframes Vi4C4a_rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.Vi4C4a_spinner {
  width: 3.25rem;
  height: 3.25rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Vi4C4a_spinner:before {
  content: "";
  width: 100%;
  height: 100%;
  background: url("spinner.e5207b42.svg") center / 100% no-repeat;
  animation: Vi4C4a_rotation 1.5s linear infinite;
  display: inline-block;
}

.FCd6ga_container {
  box-sizing: border-box;
  width: 100%;
  background-color: rgb(var(--color-magenta-secondary-rgb) / 50%);
  color: var(--color-light);
  text-align: left;
  border-radius: .5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  padding: .5rem 3rem .5rem .75rem;
  position: relative;
}

.FCd6ga_heading {
  margin: 0;
  font-size: 1rem;
}

.FCd6ga_message {
  font-style: italic;
}

.FCd6ga_icon {
  width: 2rem;
  height: 2rem;
  background: center / 100% no-repeat;
  position: absolute;
  top: .75rem;
  right: .875rem;
}

.FCd6ga_spinner {
  left: auto;
  right: 1rem;
  transform: none;
}

.FCd6ga_checkmark {
  background-image: url("checkmark.bdae4d01.svg");
}

.FCd6ga_exclamation {
  background-image: url("exclamation.987ccb29.svg");
}

.ddBG1W_container {
  margin-left: 2.75rem;
  margin-right: 3rem;
  position: relative;
}

.ddBG1W_container[aria-busy="true"] {
  filter: blur(5px);
  pointer-events: none;
}

.ddBG1W_heading {
  height: 2.5rem;
  background: 0 40% / 2.375rem no-repeat;
  margin: 0 0 2.5rem -2.75rem;
  padding-left: 2.75rem;
  font-size: 1rem;
  font-weight: normal;
  line-height: 2.5rem;
}

.ddBG1W_ctaLine {
  text-align: center;
}

.ddBG1W_ctaLink {
}

.ddBG1W_ctaButton {
  margin: 3rem auto 2.5rem;
  display: block;
}

.klsu-a_heading {
  background-image: url("email.c4c8713b.svg");
}

.klsu-a_formInput {
  margin-bottom: 0;
}

.klsu-a_inputError {
}

.klsu-a_profile {
  width: 15.625rem;
  grid-template-areas: "klsu-a_key klsu-a_value"
                       "klsu-a_key klsu-a_value";
  gap: .5rem;
  margin-bottom: 2rem;
  display: grid;
}

._2E8hBa_explainerContainer {
  z-index: 2;
  flex-direction: column;
  align-items: flex-end;
  margin: 0 0 0 2.5rem;
  display: flex;
  position: absolute;
  top: .5rem;
  right: 0;
}

._2E8hBa_showExplainer {
  width: 1rem;
  height: 1rem;
  z-index: 1;
  background: url("info.2df7c190.svg") center / 100% no-repeat;
}

._2E8hBa_hideExplainer {
  background-image: url("close.a7030d72.svg");
}

._2E8hBa_explainer {
  border: 1px solid var(--color-light);
  background-color: var(--color-magenta);
  color: var(--color-light);
  border-radius: .625rem;
  padding: .75rem;
  line-height: 1.125rem;
  position: relative;
  top: -1.5rem;
}

.GFH7gq_link {
  text-transform: uppercase;
  color: inherit;
  background: url("chevron-left.ea22bc3b.svg") 0 / 1rem no-repeat;
  margin-block: 2rem;
  padding-left: 1rem;
  font-size: .75rem;
  text-decoration: none;
  display: block;
}

.ZS0jAG_heading {
  background-image: url("twitter.e1591fed.svg");
}

.ZS0jAG_twitterInputContainer {
  width: auto;
  align-items: center;
  margin-top: .5rem;
  margin-bottom: 0;
  display: flex;
}

.ZS0jAG_twitterInputContainer:before {
  content: "@";
}

.ZS0jAG_twitterInput {
  width: 100%;
  color: inherit;
  font-size: inherit;
  background: none;
  border: none;
  padding: .25rem .25rem .25rem 0;
}

.ZS0jAG_inputError {
}

.ZS0jAG_inputError:not([hidden]) {
  width: calc(100% - 6rem);
}

.ZS0jAG_tweetContainer {
  box-sizing: border-box;
  width: 100%;
  height: auto;
  min-height: 5.5rem;
  align-items: center;
  margin-bottom: 2.5rem;
  padding: .5rem;
  display: flex;
}

.ZS0jAG_tweetInput {
  height: 100%;
  min-height: 4.5rem;
  color: inherit;
  font-size: inherit;
  background: none;
  border: none;
  flex: auto;
  align-self: stretch;
  padding: .25rem .25rem .25rem 0;
}

.ZS0jAG_profile {
  width: 15.625rem;
  grid-template-areas: "ZS0jAG_key ZS0jAG_value"
                       "ZS0jAG_key ZS0jAG_value";
  gap: .5rem;
  margin-bottom: 2rem;
  display: grid;
}

.igMBYG_copy {
  cursor: pointer;
  width: 2rem;
  height: 3rem;
  color: var(--color-white);
  background: url("copy.5534388c.svg") top no-repeat;
  border: 0;
  padding: 1.75rem 0 0;
  font-size: .875rem;
  display: inline-flex;
  position: relative;
  right: .25rem;
}

.igMBYG_copied {
}

.igMBYG_copied:after {
  content: "copied to clipboard";
  width: 9em;
  background: var(--color-magenta);
  border: 1px solid var(--color-light);
  box-shadow: -2px -2px 4px 0 rgb(var(--color-white-rgb) / 30%);
  border-radius: .75em;
  padding: .5em .75em;
  display: inline-block;
  position: absolute;
  top: 2.75em;
  left: -3.5em;
}

._sxIlG_heading {
}

._sxIlG_buttonsLine {
  text-align: center;
}

._sxIlG_ctaButton {
  margin: 0;
  display: inline-flex;
}

._sxIlG_profile {
  width: 15.625rem;
  grid-template-areas: "_sxIlG_key _sxIlG_value"
                       "_sxIlG_key _sxIlG_value";
  gap: .5rem;
  margin-bottom: 2rem;
  display: grid;
}

.xFcNJW_iframe {
  height: 2rem;
  border: none;
  overflow: hidden;
}

.xFcNJW_iframeLoading {
  visibility: hidden;
}

.Gx8ixa_heading {
  background-image: url("youtube.ff371c04.svg");
}

.Gx8ixa_buttonsLine {
  text-align: center;
}

.Gx8ixa_youtubeButton {
  margin: 0;
  display: inline-flex;
}

.Gx8ixa_youtubeButton:after {
  content: "";
  width: 5rem;
  height: 2rem;
  background: url("youtube_brand.8966d175.svg") center / 100% no-repeat;
  margin-left: .5rem;
}

.Gx8ixa_channel {
  width: 15.625rem;
  grid-template-areas: "Gx8ixa_key Gx8ixa_value"
                       "Gx8ixa_key Gx8ixa_value";
  gap: .5rem;
  margin-bottom: 2rem;
  display: grid;
}

.QpxrGW_disconnectContainer {
  gap: .75rem;
  display: inline-flex;
  position: absolute;
  top: 0;
  right: 1rem;
}

.QpxrGW_disconnect {
  height: 1.5rem;
  border-radius: .375rem;
  padding: 0 .75rem;
  font-size: .75rem;
}

/*# sourceMappingURL=index.4011a401.css.map */
